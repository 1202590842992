import {
  TLanguage,
  languages as _languages,
  defaultLanguage,
  frCommonsTranslations,
} from '@geovelo-frontends/commons';
import i18n, { ResourceKey } from 'i18next';
import { merge } from 'lodash';
import { initReactI18next } from 'react-i18next';

import fr from '../translations/fr.json';

export type TAppLanguage = TLanguage;

export const languages: {
  [key in TAppLanguage]?: {
    key: TAppLanguage;
    translation: ResourceKey;
    icon: JSX.Element;
    label: string;
  };
} = {
  fr: { ..._languages['fr'], key: 'fr', translation: merge(frCommonsTranslations, fr) },
};

i18n.use(initReactI18next).init({
  resources: languages,
  lng: defaultLanguage,
  fallbackLng: 'fr',
  debug: process.env.NODE_ENV === 'development',
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
