import { AppService } from '@geovelo-frontends/commons';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import { HelmetProvider, HelmetServerState } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { environment } from '../environment';

import i18n from './i18n';
import Router from './router';
import theme from './theme';
import './tracking';

const helmetContext = {} as {
  helmet: HelmetServerState;
};

function App(): JSX.Element {
  useEffect(() => {
    AppService.environment = environment;
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} maxSnack={3}>
          <HelmetProvider context={helmetContext}>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </HelmetProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </I18nextProvider>
  );
}

export default App;
