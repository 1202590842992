import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const RecapPage = lazy(() => import('../pages/recap'));

function Router(): JSX.Element {
  return (
    <Routes>
      <Route element={<RecapPage />} path=":token" />
    </Routes>
  );
}

export default Router;
